import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

// External components
import { JobsContext } from './JobsContext';

export const FiltersContext = createContext();

const slugify = (str) => {
	if (str) {
		return (str
			.toLowerCase()
			.trim()
			.replace(/ø/g, 'o')
			.replace(/æ/g, 'ae')
			.replace(/å/g, 'a')
			.replace(/[^\w\s-]/g, '')
			.replace(/[\s_-]+/g, '-')
			.replace(/^-+|-+$/g, ''));
	} else {
		return "";
	}
};
const FiltersProvider = ({ children }) => {
	// const [filterLoading, setFilterLoading] = useState(false);
	const { jobs } = useContext(JobsContext);
	const [jobsToShow, setJobsToShow] = useState(jobs);
	const [selectedRegions, setSelectedRegions] = useState([]); // Persist selected regions
	const [hasFilters, setHasFilters] = useState(null);
	useEffect(() => {
		setHasFilters(selectedRegions?.length > 0);
	}, [selectedRegions]);

	const resetFilters = useCallback(
		() => {
			setSelectedRegions([]);
		},
		[],
	);

	/* -------------------------------------------------------------------------- */
	/*                                   SORTING                                  */
	/* -------------------------------------------------------------------------- */
	const defaultSort = 'byPublished';
	const [sort, setSort] = useState(defaultSort); // Sort by
	const [sortDirection, setSortDirection] = useState(false); // Sort direction
	const [itemsToSort, setItemsToSort] = useState(null);
	const reverseJobs = useCallback(() => {
		setJobsToShow((j) => [...j].reverse());
	}, [setJobsToShow]);

	const sortItems = useCallback(
		(obj) => {
			const sortByWorkplace = (arr) => {
				setJobsToShow(
					[...arr].sort(function (a, b) {
						return a.workPlace.localeCompare(b.workPlace);
					})
				);
			};
			const sortByPublished = (arr) => {
				setJobsToShow([...arr].sort((a, b) => (a.id < b.id ? +1 : -1)));
			};
			const sortByDeadline = (arr) => {
				setJobsToShow(
					[...arr].sort((a, b) =>
						a.applicationDue < b.applicationDue ? -1 : +1
					)
				);
			};
			let items = obj.items;
			if (items) {
				let arr = [...items];
				if (sort === 'byDeadline') {
					sortByDeadline(arr);
				} else if (sort === 'byWorkplace') {
					sortByWorkplace(arr);
				} else if (sort === 'byPublished') {
					sortByPublished(arr);
				} else {
					let sorted = [...arr].sort((a, b) => {
						let aVal = a.id;
						let bVal = b.id;
						if (aVal < bVal) {
							return +1;
						} else {
							return -1;
						}
					});
					setJobsToShow(sorted);
				}
			}
		},
		[sort]
	);
	useEffect(() => {
		if (sort) {
			sortItems({ items: itemsToSort });
		}
	}, [itemsToSort, sort, sortItems]);

	useEffect(() => {
		if (jobs) {
			setItemsToSort(jobs);
		}
	}, [jobs]);

	/* -------------------------------------------------------------------------- */
	/*                                   SEARCH                                   */
	/* -------------------------------------------------------------------------- */

	const [searchQuery, setSearchQuery] = useState(undefined);
	const [searchedJobs, setSearchedJobs] = useState(null);
	useEffect(() => {
		let filteredJobs = searchedJobs || jobs;

		// Step 1: Filter by selected regions
		if (selectedRegions.length > 0) {
			filteredJobs = filteredJobs.filter(job =>
				selectedRegions.includes(job.locationData?.postalAreaNorway)
			);
		}

		// Step 2: Sort
		sortItems({ items: filteredJobs });

		// Step 3: Set the sorted and filtered jobs to show
		setItemsToSort(filteredJobs);
	}, [searchedJobs, selectedRegions, jobs, sortItems]);




	return <FiltersContext.Provider
		value={{
			// JOB props
			jobs,
			jobsToShow,
			setJobsToShow,

			// FILTER props
			hasFilters,
			resetFilters,
			selectedRegions,
			setSelectedRegions,

			// SORT props
			defaultSort,
			setSort,
			sortDirection,
			setSortDirection,
			reverseJobs,

			// SEARCH props
			setSearchedJobs,
			searchQuery,
			setSearchQuery
		}}
	>{children}</FiltersContext.Provider>;
};
export default FiltersProvider;
